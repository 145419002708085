@media only screen and (max-width: 1600px) {
  .match-sidebar {
    width: 280px;
  }

  /* .col-center {
    width: calc(100% - 620px);
  } */

  .policy-link {
    flex-wrap: wrap;
  }

  .col-right {
    width: 380px;
  }

  .inplay_page_wrap .col-center {
    width: calc(100% - 380px);
    padding-left: 0;
  }
}

@media only screen and (max-width: 1366px) {
  .full-wrap {
    min-width: 100%;
  }

  .match-odds-table th {
    min-width: 110px;
  }

  /* murli-css */

  .bet-all.new-bet td {
    padding: 0px 0px 0px 7px;
  }

  /* murli-css */
}

@media screen and (max-width: 1199px) {

  /* murli-css */
  .match-odd-iframe {
    width: 100%;
  }

  .match-odd-scroller {
    overflow-x: auto;
  }

  .match-top-wrapper {
    width: 461px;
  }

  .space-bet {
    width: 868px;
  }

  .special-bet-table {
    overflow-x: auto;
  }

  /* murli-css */

  /* R */
  .inplay_page_wrap .game_table_inner {
    width: 100%;
  }

  .col-center {
    width: calc(100% - 497px);
  }

  .game_table_inner,
  .match-odds-table .bets {
    width: 750px;
  }

  .game_table_main_wrapper,
  .match-odds-table {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .game-list-col dt .game-titlesmall {
    font-size: 11px;
  }

  .game-list-col dd a {
    font-size: 10px;
  }

  .col-center.inplay {
    padding-right: 15px;
    width: calc(100% - 348px);
  }

  .full-wrap {
    min-width: 100%;
    height: calc(100vh - 119px);
  }

  .col-right {
    width: 257px;
  }

  .preset_value li a {
    min-width: 45px;
  }

  /* R */

  .games_main .on_live {
    max-height: 100px;
    overflow-y: auto;
  }

  .login-wrap input, .login-wrap textarea {
    width: 130px;
}

  .games_main .title h2 {
    font-size: 13px;
  }

  .games_main .title span {
    padding: 12px 10px;
    z-index: 9;
  }

  .search-input {
    width: 250px;
  }

  .games_main .title span::before {
    z-index: -1;
  }
}

@media screen and (max-width: 991px) {
  /* murli-inner-css */
  .preset_value ul{
    justify-content: start;
  }
  .inputcanvas {
    height: 21px;
}
  .home_header .login-wrap li.user{
    background-image: none;
  }
  .login-wrap .btn-login {
    padding: 0px 12px;
}
.login-wrap .btn-login, .login-wrap .btn-signup {
  width: 62px;
}
  .col-right .login-accordion-item {
    margin: 0;
}
.col-right .bet_slip {
  padding-bottom: 53px;
}
  ul.input-list {
    text-align: center;
  }

  .total-balance-summary strong {
    color: #2789ce;
    font-size: 22px;
  }

  /* murli-inner-css */

  /* murli-css */
  .match-top-wrapper {
    width: 100%;
  }

  .pop-body figcaption h3 {
    font-size: 13px;
    padding: 0px 11px;
  }

  #popover-basic {
    min-width: auto;
  }

  /* murli-css */

  /* R */
  .col-center {
    width: calc(100% - 240px);
    padding-right: 0;
  }

  .rightbar_btn {
    background: linear-gradient(180deg, #444 13%, #1b1b1b 81%);
    border: none;
    border-radius: 50px 0 0 50px;
    width: 40px;
    height: 39px;
    position: absolute;
    top: 7px;
    right: 0px;
    z-index: 999;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  .rightbar_btn i {
    font-size: 16px;
    color: #fff;
  }

  .col-right {
    transform: translate(100%);
    position: absolute;
    right: 0;
    top: 0;
    width: 400px;
  }

  .col-center.inplay {
    width: 100%;
    padding-right: 0;
  }

  .showbet .col-right {
    transform: translate(0);
    z-index: 99;
  }

  .showbet .rightbar_btn i:before {
    content: "\f00d";
  }

  .showbet .rightbar_btn {
    transform: translateX(-399px);
    background: #e83523;
  }

  /* R */

  .search-input {
    width: 200px;
  }

  .login-wrap input, .login-wrap textarea {
    width: 102px;
}

  .on_live {
    display: none;
  }

  .policy-link {
    flex-wrap: wrap;
    text-align: center;
  }

  .policy-link li {
    margin: 0 5px 5px;
  }

  .policy-link li:after,
  .policy-link li:first-child:before {
    display: none;
  }

  /* .home_header .login-wrap li.user {
    display: none;
  } */

  .menu-wrap {
    padding: 6px 0;
    position: relative;
  }

  .navbar-toggler {
    background-color: #000;
  }

  .navbar-toggler {
    font-size: 15px;
  }

  .menu-wrap .col-sm-3,
  .navbar {
    position: static;
  }

  .navbar-collapse {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    box-shadow: 15px 10px 26px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    background-color: #fff;
  }

  .tag-live {
    top: 10px;
    right: 10px;
  }

  .col-center .tab-content {
    max-height: 100%;
    overflow-y: auto;
  }

  .bs-popover-bottom>.popover-arrow {
    display: none;
  }

  .my_account_table_wrap table {
    width: 940px;
  }

  .table-main-wrapper {
    overflow-x: auto;
  }
}

@media screen and (max-width: 767px) {



.login_right .btn-primary {
  display: flex;
  flex-direction: column;
  width: 174px;
  height: auto;
  position: relative;
  align-items: flex-start;
}

.login_right .btn-primary .head_count {
  position: absolute;
  right: 0;
  top:5px;
}
.login_right .btn-primary h5{font-size:11px;}

.login_right .btn-primary span {
  float: left;
  font-size: 11px;
  margin: 0 5px 0 0;
}
.login_right{padding:4px 10px;}


  .login-wrap .btn-login {
    padding: 0px 12px;
}
  .login_right span {
    margin: 0 10px;
  }

  .search-input {
    width: 180px;
  }

  .inplay_page_wrap .game_table_inner {
    width: 750px;
  }

  .theme_accordian .accordion-body {
    overflow-x: auto;
    overflow-y: hidden;
  }

  /* R */

  .games_main img {
    width: 100%;
  }

  .support-wrap.extend-support a {
    font-size: 12px;
  }

  .full-wrap {
    min-width: 100%;
    height: calc(100vh - 92px);
  }

  .full-wrap {
    padding: 0 10px;
  }

  .match-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 9;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transform: translateX(-100%);
  }

  .col-center {
    width: calc(100% - 0px);
    padding-right: 0;
    padding-left: 0;
  }

  .leftnav_btn {
    background: #ffc31f;
    border: none;
    padding: 10px;
    color: #fff;
    transform: rotate(-90deg);
    left: -10px;
    text-transform: uppercase;
    margin-left: 0;
    position: fixed;
    top: 160px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 0 5px 5px 0;
    z-index: 999;
    color: #000;
  }

  .sidenav .leftnav_btn {
    transform: translate(236px, -50px) rotate(90deg);
  }

  .my_account_sidebar {
    width: 100%;
    float: none;
  }

  .my_account_main {
    margin: 20px 0 0 0;
  }

  .query_link_wrapper{max-width:100%;}

  .page-middle-header{padding-top:15px;}
  .query_link_wrapper{padding:24px 0 15px 0;}


/**=====================for_mobile_device(advance)========================**/


.game-list .game-list-col > *:not(.col-matched,.col-info) {
  display: none;
}

.game_table_main_wrapper .slip-head {
  display: none!important;
}

.game_table_inner, .match-odds-table .bets {
  width: 100%;
}

.col3 .col-game, .col3 .game-list-col dt, .game-list-col .col3 dt, .slip-head .col-game, .game-list .col-matched {
  width: 100%;
}

.game-list-col .col-info {
  width: 40px;
}

.col-info .add-pin {
  width: 32px;
  height: 22px;
  background-position: -391px -77px !important;
  margin: 6px;
}

.game-list .game-list-col {
  padding: 5px 40px 5px 10px;
}

.game-list-col dt .game-titlesmall {font-size: 14px;margin-bottom: 4px;}

.col-matched p {margin: 0;}

.game-list {
  padding-top: 10px;
}

.game_filter_filter_right {
  display: none;
}

.game_list_filter_shape {
  background: -webkit-linear-gradient(top, #2e4b5e 0%, #243a48 82%);
  color: #fff;
  justify-content: center;
  padding: 10px;
}

.game_list_filter_shape h4 {
  font-size: 14px;
  font-weight: 600;
}

.game_banner {
  margin-bottom: 0;
}

.col-info .add-pin-position {width: 32px;height: 22px;background-position: -391px -15px !important;margin: 6px;}

.space-bet {
  width: 100%;
}
.match_oddds h5 {
  font-size: 16px;
}

.match_oddds {
  padding: 0 10px;
}

.price-button-pay-slip {
  font-size: 14px;
  padding: 6px 10px;
  min-width: 56px;
}

.btn-wrap .btn,.btn-wrap .btn-send {
  min-height: 32px;
  font-size: 14px;
  padding: 9px;
  line-height: 1;
}

.slip-option {
  font-size: 15px;
}

.slip-option input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

.blue_bg_tuch .min-bets {
  font-size: 14px;
  padding: 10px;
}

.team_list_wrapper h4 {
  font-size: 15px;
}

.slip-head, .matched-head {
  font-size: 13px;
}

.col-right-inner {
  position: relative;
  padding-bottom: 84px;
}
.showbet .col-right {
  z-index: 999;
}
.special_bet h3{font-size:13px;}

 .mobile_hide{display: none!important;}

 .fancy-premier-tab .nav-tabs .nav-link {
  font-size: 11px;
  padding: 6px;
  min-width: initial;
}

.match-odd-scroller .max, .match-odd-scroller .live-match {
  display: none;
}

.fancy-w-tab.table dd {
  width: 54px;
}
.mobile_large_space{width:60%;}

.premier-tab .nav-tabs{padding:5px;}
.quick-batting-wrapper .batting-flex .col-send {
  width: 100px;
}

.quick-batting-wrapper .batting-flex .up_down_rate.mx-3.quick-input {
  width: 100px;
}

.table_caption_style.profile_table.table {
  width: 100%;
}








}










@media screen and (max-width: 575px) {

  /* murli-inner-css */

  .match_oddds {
    flex-direction: column;
    align-items: start;
    position: relative;
  }

  .odds_value {
    position: absolute;
    right: 18px;
    top: 0;
  }

  .up_down_rate {
    margin-top: 14px;
  }

  .login_main {

    padding: 90px 25px 100px 20px;
  }

  .betting-time-zone label {
    min-width: 65px;
  }

  .premier-tab .nav-tabs {
    width: auto;
  }

  dd.quick-bet-confirm {
    width: 20%;

  }

  .quick-batting-wrapper .quick-input {
    width: 24%;
  }

  .quick-batting-wrapper .max-batting {
    justify-content: center;
    margin-bottom: 13px;
  }

  .quick-batting-wrapper .max-batting {
    width: 68%;

  }

  .winner-sportsbook .info-popover::before {
    height: 10px;
  }

  .quick-batting-wrapper .preset_value ul {
    justify-content: center;
  }

  .col-send {
    width: 39%;
    margin: 11px auto 0px;
  }

  .quick-batting-wrapper .batting-flex {
    flex-wrap: wrap;
  }

  .bet-history-menu ul {
    width: auto;
  }

  .bet-history-menu li a {
    font-size: 12px;
    padding: 2px 10px;
  }

  .total-balance-summary .total-left-balance {
    flex: 1;
    max-width: 100%;
    border: none;
  }

  .total-balance-summary {
    flex-direction: column;
  }

  .total-balance-summary .wlcome-sec {
    padding: 0;
    margin-top: 17px;
  }

  /* murli-inner-css */

  /* murli-css */

  .zoominout-text {
    font-size: 10px;
  }

  #popover-basic .popover-header {
    padding: 7px 17px;
    font-size: 16px;
  }

  #popover-basic {
    min-width: auto;
    transform: translate3d(16px, 414.5px, 0px) !important;
  }

  .match-top-wrapper {
    width: 500px;
  }

  .premier-tab .nav-tabs {
    margin: 0px auto 7px 9px;

  }

  .premier-tab ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  .premier-tab ::-webkit-scrollbar-thumb {
    background: transparent;
  }


  .premier-tab ::-webkit-scrollbar {
    height: 1px;
  }

  /* murli-css */

  /* sm */
  .search-input {
    width: 200px;
    margin: 0 20px;
  }

  .today_table tr td:first-child {
    font-weight: 600;
    width: 80px;
    vertical-align: middle;
  }

  .filter_popover {
    position: absolute;
    transform: translate3d(252.5px, 189.5px, 0px);
    max-width: 300px;
  }

  .today_tab {
    width: 100%;
  }

  .sports_filters {
    overflow-x: scroll;
    max-width: 560px;
    width: 100%;
  }

  /* .login_right {
    display: none;
  } */

  /* sm */

  /* R */
  .menu-wrap a.one_click {
    font-size: 11px;
  }

  .setting .btn {
    font-size: 12px;
  }

  .menu-wrap .time_zone {
    font-size: 11px;
  }

  .full-wrap {
    min-width: 100%;
    height: calc(100vh - 114px);
  }

  .time_zone span {
    display: block;
  }

  .theme_tabs .nav-tabs .nav-item .nav-link {
    font-size: 11px;
    line-height: 24px;
  }

  .showbet .rightbar_btn {
    transform: translateX(-279px);
  }

  .theme_tabs .nav-tabs {
    min-width: calc(100% - 50px);
  }

  .rightbar_btn {
    top: 4px;
  }

  .theme_tabs .nav-tabs .nav-item {
    min-width: 70px;
  }

  .col-right {
    width: 280px;
  }

  /* R */

  .footer1 {
    margin: 0px 0 0px;
    padding: 0px 0 20px;
  }

  .search-input {
    width: 160px;
  }

  .login-wrap {
    display: flex;
    align-items: center;
  }

  .menu-wrap ul {
    flex-wrap: wrap;
  }

  .menu-wrap .time_zone {
    margin: 0 6px 0 0;
    padding: 0;
  }

  .menu-wrap .setting-wrap a.setting {
    padding: 8px 0;
  }

  .home_logo img {
    max-width: 34px;
  }
  .subscribe_modal_content .nav-tabs .nav-link {
    font-size: 14px;
    padding: 12px;
}
.subscribe_modal_content .page-middle-header{margin-bottom:15px;}
.condition {
    padding: 20px 0 0 0;
}
.condition li{margin:0;}
.message_wrapper{margin-top:15px;}

.subscribe_modal_content{padding-top:36px;}



/**=============newcss-small_device===========**/

.match-top-wrapper {
  width: 100%;
}
.quick-batting-wrapper .quick-input,.quick-batting-wrapper dd {
  margin-top: 3px;
}




.table_caption_style.profile_table.table td:first-child {
  width: 110px;
}


.common-tab .nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 3px 0;
}

.common-tab .nav-tabs .nav-item .nav-link {
  font-size: 12px;
  padding: 5px 8px;
}

.bet-history-menu li a {
  padding: 2px 5px;
  font-size: 12px;
}



}




@media screen and (max-width: 479px) {

  .login_right h5{margin:0;}

  .login_right {
    padding: 4px 6px;
}

.login_right, .my_account .btn, .reload_link{padding:6px;}

  .login-wrap .btn-login,
  .login-wrap .btn-signup {
    width: auto;
    padding: 6px 12px;
    font-weight: 400;
    line-height: 1;
  }

  .change-password-sec .form-label {
    max-width: 100%;
  }

  .login-wrap li {
    margin: 0;
  }

  .login-wrap li+li {
    margin-left: 5px;
  }

  /* .home_header form {
    display: none;
  } */

  .menu-wrap ul {
    justify-content: flex-end;
  }

  .menu-wrap .time_zone {
    margin-bottom: 4px;
  }



/**======new-css-advance======**/
.games_main .title span {
  font-size: 11px;
  padding: 5px;
}

.games_main .title h2 {
  font-size: 11px;
}

.games_main .title span::before {
  border-bottom-width: 24px;
  width: 30px;
  border-left: 13px solid transparent;
  left: -14px;
}

.games_main .title h2 {
  line-height: 0.8;
  padding: 8px 7px;
}







}